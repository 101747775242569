const submitBtn = document.getElementById("payment_send");
const modalText = document.querySelector(".modal-body");

const codeInput = document.getElementById("payment_code");
const codeHelp = document.getElementById("codeHelp");

const defuntInput = document.getElementById("payment_defunt");
const defuntHelp = document.getElementById("defuntHelp");

const amountInput = document.getElementById("payment_amount");
const amountHelp = document.getElementById("amountHelp");

const typeInput = document.getElementById("payment_type");

// INPUT CODE VERIFICATIONS

codeInput.addEventListener("blur", function () {
  codeFieldCheck(codeInput, codeHelp, true);
});
codeInput.addEventListener("keyup", function () {
  codeFieldCheck(codeInput, codeHelp);
});
codeInput.addEventListener("focus", function () {
  removeFieldAlert(codeInput, codeHelp);
});
let codeError = true
function codeFieldCheck(input, help, alert){
  codeError = true;
  if (input.value == "") {
    (alert)?fieldAlert(input, help, "Le numéro de document est obligatoire") : null;
  } else if(input.value.length < 4 | input.value.length > 20 ){
    (alert)?fieldAlert(input, help, "Le numéro du document doit être compris entre 4 et 20 caractères") : null;
  } else if(!input.value.match(/^\w+$/)){
    (alert)?fieldAlert(input, help, "Le numéro de document ne comprend que des lettres, chiffres, points et underscores") : null;
  } else { 
      removeFieldAlert(input, help);
      codeError = false;
  }
  enableBtn();
}

// INPUT DEFUNT VERIFICATIONS

defuntInput.addEventListener("blur", function () {
  defuntFieldCheck(defuntInput, defuntHelp, true);
});

defuntInput.addEventListener("keyup", function () {
  defuntFieldCheck(defuntInput, defuntHelp);
});

defuntInput.addEventListener("focus", function () {
  removeFieldAlert(defuntInput, defuntHelp);
});
let defuntError = true;
function defuntFieldCheck(input, help, alert){
  defuntError = true;
  if (input.value == "") {
    (alert)?fieldAlert(input, help, "Ce champ est obligatoire, écrivez SANS si le paiement n'est pas relatif à un défunt") : null;
  } else if(input.value.length < 4 | input.value.length > 50 ){
    (alert)?fieldAlert(input, help, "Le nom du défunt doit être compris entre 4 et 50 caractères") : null;
  } else if(!input.value.match(/^[A-zÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ\-\ ]+$/)){
    (alert)?fieldAlert(input, help, "Le nom du défunt ne comprend que des lettres") : null;
  } else { 
      removeFieldAlert(input, help);
      defuntError = false;
  }
  enableBtn();
}

// INPUT AMOUNT VERIFICATIONS

amountInput.addEventListener("blur", function () {
  amountFieldCheck(amountInput, amountHelp, true);
});
amountInput.addEventListener("keyup", function () {
  amountFieldCheck(amountInput, amountHelp);
});
amountInput.addEventListener("focus", function () {
  removeFieldAlert(amountInput, amountHelp);
});

let amountError = true;
function amountFieldCheck(input, help, alert){
  amountError = true;
  if (input.value == "") {
    (alert)?fieldAlert(input, help, "Le montant est obligatoire") : null;
  } else if(input.value.length < 1 | input.value.length > 12 ){
    (alert)?fieldAlert(input, help, "Le montant doit être compris entre 1 et 12 caractères") : null;
  } else if(!input.value.match(/^[0-9\.]+$/)){
    (alert)?fieldAlert(input, help, "Entrez uniquement des chiffres, le séparateur est un point") : null;
  } else if(!input.value.match(/^[0-9]+(\.[0-9][0-9]?)?$/)){
    (alert)?fieldAlert(input, help, "Le montant ne peut avoir plus de 2 décimales") : null;
  } else { 
      removeFieldAlert(input, help);
      amountError = false;
  }
  enableBtn();
}

// FIELD ALERT FUNCTIONS

function fieldAlert(input, help, message) {
    input.classList.add("inputWarning");
    help.innerText = message;
}

function removeFieldAlert(input, help) {
    input.classList.remove("inputWarning");
    help.innerText = "";
}

// SUBMIT BTN ENABLE/DISABLE

function enableBtn(){
  if (!codeError && !defuntError && !amountError) {
    submitBtn.removeAttribute("disabled");
  } else if (
    submitBtn.getAttribute("disabled") == null &&
    codeError | defuntError | amountError
  ) {
    submitBtn.setAttribute("disabled", true);
  }
}

// MODAL DYNAMIC TEXT

submitBtn.addEventListener("click", function () {
  switch (typeInput.value) {
    case "SINGLE":
      paiementType = "comptant";
      break;
    case "MULTI":
      paiementType = "en trois fois sans frais";
      break;
  }
  modalText.innerHTML =
    "<p>Souhaitez-vous confirmer le paiement <strong>" +
    paiementType +
    "</strong> de <strong>" +
    amountInput.value +
    "</strong>  euros pour la commande/facture n°<strong>" +
    codeInput.value +
    "</strong>  ?";
});
